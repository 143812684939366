import React, { useState } from 'react';
import menuData from './MenuData';
import './MenuPage.scss'; 
import './MenuPageTablette.scss'; 
import './MenuPageMobile.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMountain, faCheese, faUtensils, faBreadSlice, faChild, faEgg, faFire, faCow, faBone, faSpoon, faBox, faFish, faBurger, faGlassMartiniAlt } from '@fortawesome/free-solid-svg-icons';

const iconMapping = {
  mountain: faMountain,
  cheese: faCheese,
  utensils: faUtensils,
  breadSlice: faBreadSlice,
  child: faChild,
  egg: faEgg,
  bone: faBone,
  spoon: faSpoon,
  box: faBox,
  fish: faFish,
  burger: faBurger,
  fondue: faFire,
  meat: faCow,
};

const MenuPage = () => {
  const [activeSection, setActiveSection] = useState(menuData[0].section);
  const [isLoading, setIsLoading] = useState(false);

  const loadSectionData = (section) => {
    setIsLoading(true);
    setActiveSection(section);

    // Simuler le chargement des données
    setTimeout(() => {
      setIsLoading(false);
    }, 900); // 200 ms pour simuler le temps de chargement, ajustez selon les besoins
  };

  return (
    <section className="menu-container">
      <div className="header-container">
        <img src={`${process.env.PUBLIC_URL}/img/logo.webp`} height={1375} width={1318} alt="Logo restaurant Le Cro Magnon" className="restaurant-logo-menu" />
        <h2>Notre carte</h2>
        <div className='blocMenuDownload'>
          <a href="https://drive.google.com/file/d/1Xqu2-hjahA2YTBZBJrkMAFm8E_OENitX/view?usp=share_link" className="download-pdf-button" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faUtensils} className="icon" /> Carte des Plats
          </a>
          <a href="https://drive.google.com/file/d/1CYPsmBxQ8HelDTCyjCoLr9b85wpgMjsD/view?usp=share_link" className="download-pdf-button" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGlassMartiniAlt} className="icon" /> Carte des Boissons
          </a>
        </div>
      </div>
      <div className="sections-nav">
        {menuData.map((section) => (
          <button
            key={section.section}
            className={`section-button ${activeSection === section.section ? 'active' : ''}`}
            onClick={() => loadSectionData(section.section)}
          >
            <FontAwesomeIcon icon={iconMapping[section.iconName]} className="icon" />
            {/* Texte présent dans le bouton pour desktop et tablette */}
            <span className="section-title-desktop">{section.section}</span>
          </button>
        ))}
      </div>
      <div className="active-section-title-mobile">
        {menuData.find(s => s.section === activeSection)?.section}
      </div>
      {isLoading ? (
        <div className="loading-spinner">
          <img src={`${process.env.PUBLIC_URL}/img/logo.webp`} alt="Logo restaurant Le Cro Magnon" className="restaurant-logo-menu" />
        </div>
      ) : (
        <div className="menu-items">
          {menuData.find(s => s.section === activeSection).items.map((item, idx) => (
            <div key={idx} className="menu-item-card appear-animation">
              <img src={item.imageUrl || '/img/placeholder.webp'} height={155} width={330} alt={item.name} className="menu-item-image" />
              <div className='menuItemsText'>
                <h3 className="menu-item-name">{item.name}</h3>
                {item.additional && <p className="menu-item-additional">{item.additional}</p>}
                <p className="menu-item-quantity">{item.quantity}</p>
              </div>
              <div className='menu-item-2'>
                <p className="menu-item-price">{item.price}</p>
              </div>
            </div>
          ))}
        </div>
      )}
      <iframe 
        src="https://docs.google.com/gview?url=https://drive.google.com/uc?id=1I2NImlVyBKecjbOuziWjvBLze2Bl9QLe&embedded=true" 
        frameBorder="0"
        title="Menu du restaurant Le Cro-Magnon"
        className="menuPDF">
      </iframe>
    </section>
  );
};

export default MenuPage;